import React from 'react'
import Page from '../../components/page'
import withRoot from '../../withRoot'

import {
  Typography,
  Collapse,
  ListItem,
  ListItemText,
  Button,
  Grid,
  Divider,
} from '@material-ui/core'

import { withStyles } from '@material-ui/core/styles'
import { DangerNote } from '../../components/highlight'

const styles = theme => ({
  parag: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  apiroot: {
    margin: 0,
    borderColor: "#61affe",
    backgroundColor: "rgba(97,175,254,.1)",
    borderWidth: "2px",
    borderStyle: "solid",
    borderRadius: "15px",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  buttonGet: {
    backgroundColor: "#61affe",
  },
  apiurl: {
    marginLeft: theme.spacing(2),
  },
  apiinfo: {
    padding: theme.spacing(2)
  },
  response: {
    magin: theme.spacing(1)
  },
  code: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    // backgroundColor: "#41444E",
    // borderColor: "#41444E",
    backgroundColor: "#4f566b",
    borderColor: "#4f566b",
    color: "#c1c9d2",
    borderWidth: "0px",
    borderStyle: "solid",
    borderRadius: "10px",
    fontSize: "12px",
    fontWeight: "500",
  },
  subcode: {
    padding: theme.spacing(1),
  },
  codeTitle: {
    fontSize: "12px",
    fontWeight: "500",
    textTransform: "uppercase",
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
    backgroundColor: "#3c4257",
    color: "#a3acb9",
    padding: theme.spacing(1),
  }
})

@withStyles(styles)
class Api extends React.Component {
  constructor(props) {
    super(props)
    this.state = { open: false }
  }

  render() {
    const { classes } = this.props
    return (
      <div className={classes.apiroot}>
        <ListItem button onClick={() => this.setState({ open: !this.state.open })}>
          <ListItemText>
            <Grid container direction="row" justify="space-between" align="center">
              <Grid item>
                <Grid container direction="row" justify="left" align="center">
                  <Button className={classes.buttonGet} size="small" variant="contained">
                    {this.props.method}
                  </Button>
                  <Typography color="inherit" className={classes.apiurl} variant="h6">{this.props.url}</Typography>
                </Grid>
              </Grid>
              <Grid item>
                <Typography>{this.props.title}</Typography>
              </Grid>
            </Grid>
          </ListItemText>
        </ListItem>
        <Collapse in={this.state.open} timeout="auto" unmountOnExit>
          <Divider className={classes.buttonGet} />
          <div className={classes.apiinfo}>
            {this.props.children}
          </div>
          <div className={classes.apiinfo}>
            Response :
            <code>
              <pre>
                {JSON.stringify(this.props.response, null, 2)}
              </pre>
            </code>
          </div>
        </Collapse>
      </div>
    )
  }
}

@withStyles(styles)
class Code extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { classes } = this.props
    return (
      <div className={classes.code}>
        <Typography className={classes.codeTitle}>{this.props.title}</Typography>
        <div className={classes.subcode}>
          <code>{this.props.children}</code>
        </div>
      </div>
    )
  }
}

@withRoot
@withStyles(styles)
export default class ApiDoc extends React.Component {
  render() {
    const { classes } = this.props
    return (
      <Page toggleTheme={this.props.toggleTheme} title="Developer API">
        <Typography className={classes.parag}>
          FitFace API allows premium users to get their sales statistics in a simple JSON format to use in their dashboards.<br />
        </Typography>
        <DangerNote>
          This is for advanced user. Do not generate api keys if you don&apos;t know how to use it.
          Keep your api keys secret because they will give anyone access to your datas. If a key gets compromised, just revoke it.
          Only Generate keys if you need it, and revoke the one you don&apos;t use.
        </DangerNote>
        <Code title="Base Url">https://fitface.xyz/api/v1/</Code>
        <Typography className={classes.parag} variant="h5" color="secondary">Authentication</Typography>
        <Typography className={classes.parag}>
          The FitFace API uses API keys to authenticate requests.
          You can view and manage your API keys in the FitFace Settings Dashboard.
          Your API keys carry many privileges, so be sure to keep them secure!
          Do not share your secret API keys in publicly accessible areas such as GitHub, client-side code, and so forth.
        </Typography>
        <Typography className={classes.parag}>
          You can authenticate via X-API-KEY header, use -H &quot;X-API-KEY: your_api_key&quot;
        </Typography>
        <Code title="Authenticated request">$ curl -H &quot;X-API-KEY: your_api_key&quot; https://fitface.xyz/api/v1/sales/summary</Code>

        <Typography className={classes.parag}>
          You can also pass your key as a GET parameter on the url. However this is not the recommanded way as your key can be easily leaked if you are not carefull.
        </Typography>
        <Code title="Authenticated GET request">$ curl https://fitface.xyz/api/v1/sales/summary?key=your_api_key</Code>

        <Typography className={classes.parag} variant="h5" color="secondary">Errors</Typography>
        <Typography className={classes.parag}>
          FitFace uses conventional HTTP response codes to indicate the success or failure of an API request.
          In general: Codes in the 2xx range indicate success.
          Codes in the 4xx range indicate an error that failed given the information provided (e.g., a required parameter was omitted, a charge failed, etc.).
          Codes in the 5xx range indicate an error with FitFace&apos;s servers (these should not happen).
        </Typography>
        <Code title="HTTP Status Code Summary">
          200 - OK Everything worked as expected.<br />
          400 - Bad Request : The request was unacceptable, often due to missing a required parameter.<br />
          401 - Unauthorized : No valid API key provided.<br />
          402 - Request Failed : The parameters were valid but the request failed.<br />
          403 - Forbidden : The API key doesn&apos;t have permissions to perform the request.<br />
          404 - Not Found : The requested resource doesn&apos;t exist.<br />
          409 - Conflict : The request conflicts with another request (perhaps due to using the same idempotent key).<br />
          429 - Too Many Requests :  Too many requests hit the API too quickly. We recommend an exponential backoff of your requests.<br />
          50x - Server Errors : Something went wrong on FitFace&apos;s end. (these should not happen)<br />
        </Code>

        <Typography className={classes.parag} variant="h5" color="secondary">API</Typography>
        <Api
          method="GET"
          url="/sales/summary"
          title="Get an overview of your sales"
          response={{
            currentBalance: 254.13,
            totalPayout: 14549.28,
            totalSales: 15275
          }}
        >
          No parameters
        </Api>
        <Api
          method="GET"
          url="/sales/today"
          title="Get your sales for today"
          response={{
            amount: 51.21,
            date: "2019-10-30",
            sales: 45
          }}
        >
          <Typography>Parameters:</Typography>
          <Typography variant="subtitle2">
            tz (optional): The time difference between UTC time and local time, in minutes.
            For example, If your time zone is GMT+2, -120 will be returned.
            Note: The returned value is not a constant, because of the practice of using Daylight Saving Time.
            Same value as returned by JavaScript code new Date().getTimezoneOffset().
          </Typography>
        </Api>
        <Api
          method="GET"
          url="/sales/history"
          title="Get your sales history"
          response={{
            count: 3,
            page: 1,
            pageSize: 100,
            results: [
              {
                authorPaid: false,
                date: "2019-04-17T13:58:38.940000+02:00",
                model: "Ionic",
                name: "Mario Maddox",
                refunded: false
              },
              {
                authorPaid: true,
                date: "2019-04-17T13:36:20.402000+02:00",
                model: "Versa",
                name: "Brody Orr",
                refunded: false
              },
              {
                authorPaid: false,
                date: "2019-04-16T18:12:02.962000+02:00",
                model: "Ionic",
                name: "Asa Mccallum",
                refunded: true
              }
            ]
          }}
        >
          <Typography>Parameters:</Typography>
          <Typography variant="subtitle2">page (optional): current page</Typography>
          <Typography variant="subtitle2">page_size (optional): item per pages, (maximum = 100)</Typography>
        </Api>
      </Page>
    )
  }
}
